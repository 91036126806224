.ant-table-wrapper.events-table tr:hover  > td, 
.ant-table-wrapper.events-table tr:active > td, 
.ant-table-wrapper.events-table tr:focus  > td { background: transparent; }


.app-loader {}
.app-loader:before {
  z-index: 99000;
  position: fixed;
  top: 0;
  left: 0;
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background: #f0f0f0;
}

.app-loader:after {
  z-index: 99001;
  position: fixed;
  top: 1em;
  left: calc(50% - 18px);
  content: '';
  display: block;
  width: 36px;
  height: 36px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #1890ff;
  border-color: #1890ff transparent #1890ff #1890ff;
  animation: lds-dual-ring 0.9s linear infinite;
}


@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.content-wrapper { display: flex; align-items: stretch; }
.content-wrapper__menu { width: 20%; }
.content-wrapper__records-table { width: 80%; }


@media print {
  .content-wrapper__menu { width: 0%; display: none; }
  .content-wrapper__records-table { width: 100%; }
}
