.header { padding: 0 15px; }

.header__content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__menu {}

.header__logo>img { width: 200px; }

.header__date { display: none; }

.header__notifications {}


@media (min-width: 500px) {
  .header__logo>img { width: 320px; }
}

@media print {
  .header__menu,
  .header__notifications { display: none; }

  .header__date { display: inline-block; margin-left: .5em; color: #fff; }
}
