.record-item { margin: .25em 0; overflow-wrap: break-word; max-width: 90px; }


@media (min-width: 400px) { .record-item { max-width: 130px; } }
@media (min-width: 500px) { .record-item { max-width: 180px; } }
@media (min-width: 600px) { .record-item { max-width: 230px; } }
@media (min-width: 700px) { .record-item { max-width: 280px; } }

@media print {
  .record-item { background: transparent; color: #000; }
}
