.time-picker { color: #69c0ff; font-weight: bold; }

.time-picker__time { color: #fff; font-size: 1.5em; text-align: center; margin-bottom: 1em; }

.time-picker__hours,
.time-picker__minutes {
  border: dashed 1px #69c0ff;
  display: inline-block;
  padding: 1em;
  border-radius: 5px;
  color: #999;
}

.time-picker__divider { display: inline-block; color: #69c0ff; margin: 5px; font-size: 2em; }
